import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Box } from "@chakra-ui/react"

import AuthorCard from "../components/AuthorCard"
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"
import ArticleCard from "./hub/article-card"

const Author = ({ data }) => {
  const articles = data.allPrismicArticle.nodes
  const author = data.prismicAuthor
  return (
    <Layout heroImage={data.prismicAuthors.data.hero_image} heroCopy={data.prismicAuthor.data.name}>
      <SEO node={author} />
      <Section mt={16}>
        <Box
          sx={{
            display: "grid",
            gridGap: 5,
            alignItems: "start",
            gridTemplateColumns: ["1fr", "1fr 2fr"],
          }}
        >
          <AuthorCard author={author} />
          <Box
            sx={{
              display: "grid",
              gridGap: 5,
              gridTemplateColumns: ["1fr", "1fr 1fr"],
            }}
          >
            {[...articles].map((a) => (
              <ArticleCard key={a.uid} article={a} />
            ))}
          </Box>
        </Box>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query AuthorQuery($authorUid: String!) {
    allPrismicArticle(
      filter: { data: { country: {}, articletype: {}, author: { uid: { eq: $authorUid } } } }
      sort: { fields: [first_publication_date], order: DESC }
    ) {
      nodes {
        data {
          country
          location
          title
          previewtext
          articletype
          author {
            document {
              ... on PrismicAuthor {
                data {
                  name
                  avatar {
                    fluid {
                      __typename
                    }
                  }
                }
                first_publication_date
                uid
                type
              }
            }
          }
          heroimage {
            gatsbyImageData(height: 236)
          }
        }
        first_publication_date(formatString: "DD MMMM YYYY")
        uid
        type
      }
    }
    prismicAuthor(uid: { eq: $authorUid }) {
      uid
      type
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        aboutme {
          text
        }
        name
        avatar {
          gatsbyImageData(height: 300)
        }
      }
    }
    prismicAuthors {
      data {
        hero_copy
        hero_image {
          gatsbyImageData(width: 2560, height: 700)
        }
      }
    }
  }
`

Author.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

export default Author
